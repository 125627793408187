.trans-tabels {
  margin: 20px;
  display: flex;
}
.bill-table {
  margin-right: 10px;
  float: left;
  width: 70%;
}
.temp-table {
  float: right;
  width: 30%;
}

.payableAmount {
  font-size: 20px;
  font-weight: 900;
}

.datepicker-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100px; */
}
.datepicker-container .react-datepicker-wrapper {
  margin: 12px 0;
  width: 100%;
}

.datepicker-container .react-datepicker-wrapper input {
  padding: 4px 12px;
  /* width: 98px; */
}

@media screen and (max-width: 820px) {
  .trans-tabels {
    display: block;
  }
  .bill-table {
    width: 100%;
  }
  .temp-table {
    width: 100%;
  }
  .datepicker-container {
    margin: auto;
    width: 110px;
  }
  .datepicker-container .react-datepicker-wrapper input {
    width: 80px;
  }
}
