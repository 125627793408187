.login-wrapper {
  height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-wrapper {
  width: 35rem;
  /* height: 500px; */
}

.submit-btn {
  float: right;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}

.heading {
  text-align: center;
  color: #b5bac0;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .form-wrapper {
    width: 95%;
  }
}
