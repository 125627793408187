.navbarrr {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.navGlass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.37);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.4px);
  -webkit-backdrop-filter: blur(6.4px);
  border: 1px solid rgba(255, 255, 255, 0.57);
}

.navLink {
  margin: 5px;
  text-decoration: none;
  color: inherit;
  background-color: #cac7c7;
  float: left;
  display: block;
  text-align: center;
  padding: 4px 10px;
  border-radius: 35px;

  transition-property: background, border-radius;
  transition-duration: 0.3s;
  transition-timing-function: linear;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.37);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.4px);
  -webkit-backdrop-filter: blur(6.4px);
  border: 1px solid rgba(255, 255, 255, 0.57);
}
.navLink:hover {
  background-color: #79d8d8;
  color: rgb(255, 255, 255);
  border-radius: 40px;
}
